import React from 'react';
import { useSalesforceCoSellWidgetButtonGroupStyles } from './SalesforceCoSellWidgetButtonGroup.styles';
import { Button, Grid } from 'vendor/material';

interface SalesforceCoSellWidgetButtonGroupProps {
  tackleCoSellId?: string;
}

export const SalesforceCoSellWidgetButtonGroup = (
  props: SalesforceCoSellWidgetButtonGroupProps,
) => {
  const classes = useSalesforceCoSellWidgetButtonGroupStyles();

  return (
    <div className={classes.buttonGroupContainer}>
      {props.tackleCoSellId && (
        <Button
          className={classes.dashboardButtonElement}
          variant="contained"
          color="primary"
          size="large"
          href={`https://downstream.tackle.io/co-sell/${props.tackleCoSellId}`}
          target="_blank"
        >
          View Co-Sell Opportunity
        </Button>
      )}
      <Grid container className={classes.bottomButtonContainer}>
        <Grid item xs={6}>
          <Button
            className={classes.buttonElement}
            variant="outlined"
            color="primary"
            href="https://downstream.tackle.io/co-sell"
            target="_blank"
          >
            Dashboard
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonElement}
            variant="outlined"
            color="primary"
          >
            Create Co-Sell
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
