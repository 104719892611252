import coSellClient from 'packages/cosell/api/coSellClient';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { solutionsApiToFormFormatter } from 'packages/cosell/src/utilities/formatSolutions';
import { createContext, useContext, useEffect, useState } from 'react';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { ErrorResponse } from 'packages/cosell/src/types';
import { isEmpty } from 'lodash-es';
interface OpportunityContextProps {
  vendorId: string;
  token: string;
  solutionList: Array<{ value: string; display: string }>;
  awsEnumListMap: AwsEnumListResponse;
  // @todo - type coSellClient
  coSellClient: any;
  opportunity: AceOpportunityResponse;
  opportunityError: ErrorResponse;
}

export const OpportunityContext = createContext<OpportunityContextProps>({
  vendorId: null,
  token: null,
  solutionList: null,
  opportunity: null,
  opportunityError: null,
  awsEnumListMap: null,
  coSellClient: null,
});

export interface OpportunityProviderProps {
  vendorId?: string;
  token?: string;
  opportunityId?: string;
  children?: React.ReactElement;
}

export const OpportunityProvider = ({
  vendorId,
  token,
  children,
  opportunityId,
}: OpportunityProviderProps) => {
  const [solutionList, setSolutionList] = useState<
    Array<{ display: string; value: string }>
  >([]);
  const [awsEnumListMap, setAwsEnumListMap] =
    useState<AwsEnumListResponse>(null);
  const [opportunity, setOpportunity] = useState<AceOpportunityResponse>(null);
  const [opportunityError, setOpportunityError] = useState<ErrorResponse>(null);

  const authorizedCosellClient = coSellClient(token);

  useEffect(() => {
    const getVendorSolutions = async () => {
      const vendorSolutionsData =
        await authorizedCosellClient?.getVendorSolutionsByVendorAndCloud(
          vendorId,
          'ace',
        );
      const solutionList = solutionsApiToFormFormatter(vendorSolutionsData);
      setSolutionList(solutionList);
    };
    if (solutionList?.length === 0 && authorizedCosellClient) {
      getVendorSolutions();
    }
  }, [vendorId, authorizedCosellClient, solutionList]);

  useEffect(() => {
    const getAwsCoSellEnumOptions = async () => {
      const awsCoSellEnumValues =
        await authorizedCosellClient?.getAwsCoSellEnumOptions();
      setAwsEnumListMap(awsCoSellEnumValues);
    };
    if (awsEnumListMap === null && authorizedCosellClient) {
      getAwsCoSellEnumOptions();
    }
  }, [authorizedCosellClient, awsEnumListMap]);

  useEffect(() => {
    const getOpportunityDetails = async () => {
      const opportunityDetails =
        await authorizedCosellClient?.getOpportunityByIdV3(opportunityId);
      if (opportunityDetails instanceof Error) {
        setOpportunityError(opportunityDetails);
        return;
      }
      setOpportunity(opportunityDetails as AceOpportunityResponse);
    };
    if (isEmpty(opportunity) && opportunityId && authorizedCosellClient) {
      getOpportunityDetails();
    }
  }, [opportunityId, authorizedCosellClient, opportunity]);

  return (
    <OpportunityContext.Provider
      value={{
        vendorId,
        token,
        solutionList,
        awsEnumListMap,
        coSellClient,
        opportunity,
        opportunityError, // TODO: Add error handling
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error('useOpportunity must be used within a OpportunityProvider');
  }
  return context;
}
