import { useEffect } from 'react';
import CanvasSdk from './lib/salesforceCanvasSdk';
import {
  CanvasBulkCreateCosell,
  CanvasCreateCoSell,
  CanvasCoSellDetails,
} from '../cosell/src';
import { Loader } from '@tackle-io/platform-ui';
import {
  CanvasSessionProvider,
  useCanvasSession,
} from './providers/CanvasSessionProvider';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { OpportunityProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';

const CanvasApp = () => {
  const { canvasSession, isLoadingCanvasSession } = useCanvasSession();
  const classes = useCanvasAppStyles();

  useEffect(() => {
    if (
      !canvasSession ||
      !canvasSession.context ||
      !canvasSession.context.client
    )
      return;
    CanvasSdk.canvas.client.autogrow(canvasSession.context.client, true, 100);
  }, [canvasSession]);

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (
    !canvasSession ||
    !canvasSession.context ||
    !canvasSession.context.action
  ) {
    return <div>No context provided.</div>;
  }

  const getComponentToRender = () => {
    switch (canvasSession.context.action) {
      case 'CoSellDetails':
        return <CanvasCoSellDetails />;
      case 'CreateNewCoSellForm':
        return <CanvasCreateCoSell />;
      case 'BulkCreateCoSell':
        return <CanvasBulkCreateCosell />;
      default:
        return (
          <div>Unknown context: {canvasSession.context.action} provided.</div>
        );
    }
  };

  return (
    <OpportunityProvider token={canvasSession.access_token}>
      <AwsOpportunityActionsProvider token={canvasSession.access_token}>
        <div className={classes.canvasAppContainer}>
          {getComponentToRender()}
        </div>
      </AwsOpportunityActionsProvider>
    </OpportunityProvider>
  );
};

const CanvasAppWithSessionProvider = () => {
  return (
    <CanvasSessionProvider>
      <CanvasApp />
    </CanvasSessionProvider>
  );
};

export default CanvasAppWithSessionProvider;
