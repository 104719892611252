import React from 'react';
import { Box, StandardTextFieldProps, Tooltip } from 'vendor/material';
import { InformationOutline } from 'mdi-material-ui';
import { TextField } from '@tackle-io/platform-ui';
import useStyles from './TextFormField.styles';
import { useFormikContext } from 'formik';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { Label } from '../Label';

interface TextFormFieldProps extends StandardTextFieldProps {
  label: string;
  name: string;
  maxLength?: number;
  minLength?: number;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  tooltipText?: string;
  helperText?: string;
  pendoId?: string;
  // ['aria-invalid']?: boolean | 'true' | 'false' | 'grammar' | 'spelling';
  // ['aria-disabled']?: boolean;
}

export const TextFormField: React.FC<TextFormFieldProps> = ({
  name,
  label,
  maxLength,
  minLength,
  multiline = false,
  rows = 1,
  required = false,
  tooltipText,
  pendoId,
  helperText,
  disabled,
  'aria-invalid': ariaInvalid,
}) => {
  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext<UnifiedOpportunityFormValues>();
  const classes = useStyles();

  const textFieldLabel = tooltipText ? (
    <>
      <Box className={classes.formLabel}>
        <Label label={label} required={required} />
        <Tooltip
          id={pendoId}
          className={classes.tooltip}
          title={tooltipText}
          placement="top-start"
        >
          <InformationOutline fontSize="small" />
        </Tooltip>
      </Box>
    </>
  ) : (
    <Label label={label} required={required} />
  );

  return (
    <>
      <TextField
        data-id={pendoId}
        id={name}
        name={name}
        label={textFieldLabel}
        multiline={multiline}
        rows={rows}
        maxLength={maxLength}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={helperText}
        value={values[name]}
        error={touched[name] && errors[name]}
        aria-disabled={disabled}
        aria-invalid={ariaInvalid}
      />
    </>
  );
};
