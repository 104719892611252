import React from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/providers/CanvasSessionProvider';
import { SalesforceCoSellWidget } from 'packages/cosell/src/components/SalesforceCoSellWidget/';
import { get } from 'lodash';

export default function CanvasCoSellDetails() {
  const { canvasSession } = useCanvasSession();
  const salesforceOpportunityId = get(canvasSession, 'context.record.Id');

  if (!salesforceOpportunityId) {
    return (
      <p>
        Unable to get Salesforce Opportunity ID. Please verify with your admin
        this component is on the Salesforce Opportunity page.
      </p>
    );
  }

  // Replace with useOpportunity() here and type it to AceOpportunityResponse
  // useOpportunity needs a getOpportunityBySalesforceId method to use in this component
  const mockOpportunity: any = {
    id: 'test-id',
    accessControls: {
      nationalSecurity: 'No',
      owner: {
        email: 'test-email@test.io.invalid',
        firstName: 'Tac',
        lastName: 'BD Team',
      },
    },
    awsOpportunityTeam: [
      {
        businessTitle: 'WWPSPDM',
        email: 'acc6-XYZ@sec.dmk',
      },
      {
        businessTitle: 'PDM',
        email: 'pdm-21@sec.dtk.a1x8a00000aabcpxxb',
        firstName: 'Juan',
        lastName: 'Smith',
      },
    ],
    catalog: 'Sandbox',
    createdDate: '2024-08-19T15:30:20Z',
    identifier: 'O1234567',
    customer: {
      account: {
        companyName: 'Test INC.',
        websiteUrl: 'https://www.tackle.com',
        industry: 'Computers and Electronics',
        address: {
          countryCode: 'US',
          postalCode: '33351',
          stateOrRegion: 'Florida',
        },
      },
    },
    lastModifiedDate: '2024-08-19T16:00:08Z',
    opportunityType: 'Net New Business',
    origin: 'Partner Referral',
    lifeCycle: {
      reviewStatus: 'Submitted',
      stage: 'Prospect',
      targetCloseDate: '2024-09-18',
    },
    marketing: {
      source: 'None',
    },
    partnerOpportunityTeam: [
      {
        businessTitle: 'PartnerAccountManager',
        email: 'tc@tackle.io',
        firstName: 'Timothy',
        lastName: 'Cazu',
      },
    ],
    partnerOpportunityIdentifier: salesforceOpportunityId,
    primaryNeedsFromAws: [
      'Co-Sell - Pricing Assistance',
      'Co-Sell - Deal Support',
    ],
    solutions: ['S-0014870'],
    awsProducts: [],
    crmId: 'a2uOv000001whwrIAA',
    crmOrgId: 'testOrg',
    project: {
      additionalComments: 'a random text',
      customerBusinessProblem: 'An example business problem that needs solving',
      customerUseCase: 'AI Machine Learning and Analytics',
      deliveryModels: ['SaaS or PaaS'],
      expectedMonthlyAwsRevenue: {
        amount: 98765,
        currencyCode: 'USD',
      },
      salesActivities: [
        'Initialized discussions with customer',
        'Customer has shown interest in solution',
      ],
      title: 'Test opportunity',
    },
  };

  return <SalesforceCoSellWidget coSellOpportunity={mockOpportunity} />;
}
