import React from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/providers/CanvasSessionProvider';

export default function CanvasBulkCreateCosell() {
  const { canvasSession } = useCanvasSession();
  return (
    <>
      <p style={{ maxWidth: '100%' }}>
        <pre>{JSON.stringify(canvasSession, null, 2)}</pre>
      </p>
    </>
  );
}
