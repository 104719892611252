import React from 'react';
import { SalesforceCoSellWidgetHeader } from './SalesforceCoSellWidgetHeader/SalesforceCoSellWidgetHeader';
import { SalesforceCoSellWidgetDetailTable } from './SalesforceCoSellWidgetDetailTable/SalesforceCoSellWidgetDetailTable';
import { SalesforceCoSellWidgetButtonGroup } from './SalesforceCoSellWidgetButtonGroup/SalesforceCoSellWidgetButtonGroup';
import { SalesforceCoSellWidgetCallToAction } from './SalesforceCoSellWidgetCallToAction/SalesforceCoSellWidgetCallToAction';

interface SalesforceCoSellWidgetProps {
  // @to-do type this to AceOpportunityResponse once that contract is finalized
  coSellOpportunity: any;
}
export const SalesforceCoSellWidget = (props: SalesforceCoSellWidgetProps) => {
  return (
    <>
      <SalesforceCoSellWidgetHeader />

      {props.coSellOpportunity.partnerOpportunityIdentifier ===
      '006Oz000009hA1uIAE' ? (
        <>
          <SalesforceCoSellWidgetCallToAction />
          <SalesforceCoSellWidgetButtonGroup />
        </>
      ) : (
        <>
          <SalesforceCoSellWidgetDetailTable
            opportunity={props.coSellOpportunity}
          />
          <SalesforceCoSellWidgetButtonGroup
            tackleCoSellId={props.coSellOpportunity.id}
          />
        </>
      )}
    </>
  );
};
