import { Box, Tooltip } from 'vendor/material';
import { ComboBox } from '@tackle-io/platform-ui';
import { InformationOutline } from 'mdi-material-ui';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import useStyles from './SingleSelectFormField.styles';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { SelectFormFieldOption } from '../../types';
import { Label } from '../Label';

const DEFAULT_OPTION = '--None--';

interface SingleSelectFormFieldProps {
  fieldId: string;
  options: Array<{ value: string; display: string }>;
  label: string;
  disabled?: boolean;
  filterSelectedOptions?: boolean;
  tooltipText?: string;
  required?: boolean;
  dataId?: string;
  onChange?: (value?: string | number) => void;
}

export const SingleSelectFormField: React.FC<SingleSelectFormFieldProps> = ({
  disabled,
  fieldId,
  label,
  options = [],
  tooltipText,
  required,
  dataId = null,
  onChange,
}) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const classes = useStyles();

  // It's important to memoize options here because our multiselect field
  // determines options and selected option equality by reference.
  const memoizedOptions: SelectFormFieldOption[] = useMemo(() => {
    const optionsToMap = options ? options : [];
    return optionsToMap.map((x) => ({
      title: x.display,
      value: x.value,
    }));
  }, [options]);

  const value = values?.[fieldId]
    ? memoizedOptions.find((x) => x.value === values?.[fieldId])
    : null;
  const handleSingleSelectChange = (
    _event: React.SyntheticEvent<Element, Event>,
    selectedOption: SelectFormFieldOption,
  ) => {
    setFieldTouched(fieldId, true);
    setFieldValue(fieldId, selectedOption?.value);
    onChange?.(selectedOption?.value);
  };

  const textFieldLabel = tooltipText ? (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${fieldId}Label`}
    >
      <Label label={label} required={required} />
      <Tooltip
        className={classes.tooltip}
        title={tooltipText}
        placement="top-start"
      >
        <InformationOutline fontSize="small" />
      </Tooltip>
    </Box>
  ) : (
    <Box
      className={classes.formLabel}
      data-id={dataId ? dataId : `${fieldId}Label`}
    >
      <Label label={label} required={required} />
    </Box>
  );

  return (
    <ComboBox
      key={value?.value}
      disabled={disabled}
      hasError={touched[fieldId] && errors?.[fieldId]}
      id={fieldId}
      label={textFieldLabel}
      multiple={false}
      onChange={handleSingleSelectChange}
      options={memoizedOptions}
      value={value}
      placeholder={DEFAULT_OPTION}
      description={touched[fieldId] && errors?.[fieldId]}
    />
  );
};
