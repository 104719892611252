import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import {
  DisplayCloudType,
  OpportunityStatusEnum,
} from 'packages/cosell/src/types/enums';
import { ampli } from 'utils/analytics/ampli';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import coSellClient from 'packages/cosell/api/coSellClient';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { isEmpty } from 'lodash-es';

interface AceOpportunityFormProps {
  opportunityId?: string;
}
const AceOpportunityForm = ({ opportunityId }: AceOpportunityFormProps) => {
  const { token, opportunity } = useOpportunity();

  const authorizedCosellClient = coSellClient(token);

  const handleCreateOrUpdateOpportunity = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    // TODO: If opportunityId exists, use updateOpportunity
    return await authorizedCosellClient.createOpportunity(requestBody);
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    try {
      const submittedOpportunity = await handleCreateOrUpdateOpportunity(
        values,
      );
      ampli.outboundSubmitted({ cloud: 'aws' });
      return submittedOpportunity;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    values.status =
      values.status && values.status === OpportunityStatusEnum.INTERNAL_REVIEW
        ? OpportunityStatusEnum.INTERNAL_REVIEW
        : OpportunityStatusEnum.DRAFT;
    const saveDraftOpportunity = handleCreateOrUpdateOpportunity(values);
    return saveDraftOpportunity;
  };
  const flattenedAceFormValues =
    !isEmpty(opportunity) &&
    convertOpportunityResponseToAceFormValues(opportunity);
  return (
    <UnifiedOpportunityFormShell
      opportunityId={opportunityId}
      opportunity={opportunity}
      onSubmit={handleSubmitAceOpportunityToCloud}
      onSaveAsDraft={handleSaveAceOpportunityAsDraft}
      initialValues={flattenedAceFormValues ? flattenedAceFormValues : {}}
      provider={DisplayCloudType.AWS}
      validationSchema={aceOpportunityFormValidationSchema}
    >
      <AceOpportunityFormFields />
    </UnifiedOpportunityFormShell>
  );
};

export default AceOpportunityForm;
