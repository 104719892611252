import { CreateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { AcePartnerNeedType } from '../../types/enums';
import {
  AceOpportunityResponse,
  Address,
  Contact,
  Customer,
  CustomerAccount,
  LifeCycle,
  Marketing,
  PartnerOpportunityTeamMember,
  Project,
} from '../../types/responses/AceOpportunityResponse';

// To re-inforce all fields from get request to form fieldNames
export const FormFieldPathMap = {
  duns: 'customer.account.duns',
  customerCompanyName: 'customer.account.companyName',
  industry: 'customer.account.industry',
  industryOther: 'customer.account.otherIndustry',
  nationalSecurity: 'accessControls.nationalSecurity',
  customerWebsite: 'customer.account.websiteUrl',
  countryCode: 'customer.account.address.countryCode',
  postalCode: 'customer.account.address.postalCode',
  state: 'customer.account.address.stateOrRegion',
  city: 'customer.account.address.city',
  address: 'customer.account.address.streetAddress',
  partnerNeedType: 'opportunityType', // only needed for other fields but not submitted to payload
  primaryNeedsFromAws: 'awsOpportunityTeam',
  opportunityType: 'opportunityType',
  parentOppId: 'parentOpportunityId',
  projectTitle: 'customer.account.projectTitle',
  projectDescription: 'lifeCycle.nextStepsHistory[0].value',
  solutions: 'customer.solutions',
  otherSolutionDescription: 'customer.otherSolutionDescription',
  awsProducts: 'customer.awsProducts',
  nextStep: 'lifeCycle.nextSteps',
  useCase: 'lifeCycle.useCase',
  deliveryModel: 'customer.deliveryModel',
  expectedMonthlyRevenue: 'customer.expectedMonthlyRevenue',
  targetCloseDate: 'lifeCycle.targetCloseDate',
  apnPrograms: 'project.apnPrograms',
  isOppFromMarketingActivity: 'customer.isOppFromMarketingActivity',
  campaignName: 'marketing.campaignName',
  marketingActivityUseCases: 'customer.marketingActivityUseCases',
  marketingActivityChannel: 'customer.marketingActivityChannel',
  isMarketingDevelopmentFunded: 'marketing.awsFundingUsed',
  competitiveTracking: 'project.competitiveTracking',
  otherCompetitorNames: 'customer.otherCompetitorNames',
  awsAccountId: 'customer.awsAccountId',
  additionalComments: 'customer.additionalComments',
  customerFirstName: 'customer.contact.firstName',
  customerLastName: 'customer.contact.lastName',
  customerTitle: 'customer.contact.businessTitle',
  customerEmail: 'customer.contact.email',
  customerPhone: 'customer.contact.phone',
  primaryContactFirstName: 'accessControls.owner.firstName',
  primaryContactLastName: 'accessControls.owner.lastName',
  primaryContactTitle: 'awsOpportunityTeam[0].businessTitle',
  primaryContactEmail: 'accessControls.owner.email',
  primaryContactPhone: 'awsOpportunityTeam[0].phone',
};

const getPartnerNeedType = (response): AcePartnerNeedType =>
  response.primaryNeedsFromAws
    ? AcePartnerNeedType.CO_SELL_WITH_AWS
    : AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP;

export const convertOpportunityResponseToAceFormValues = (
  response: AceOpportunityResponse,
): CreateAceOpportunityFormValues => {
  const getAddressValues = (address: Address) => ({
    city: address.city,
    countryCode: address.countryCode,
    postalCode: address.postalCode,
    state: address.stateOrRegion,
    address: address.streetAddress,
  });

  const getAccessControls = (response: AceOpportunityResponse) => ({
    nationalSecurity: response.accessControls.nationalSecurity,
  });

  const getAccountValues = (account: CustomerAccount) => ({
    duns: account.duns,
    awsAccountId: account.awsAccountId,
    customerCompanyName: account.companyName,
    industry: account.industry,
    industryOther: account.otherIndustry,
    customerWebsite: account.websiteUrl,
    ...getAddressValues(account.address),
  });

  const getContactValues = (contact: Contact) => ({
    customerFirstName: contact.firstName,
    customerLastName: contact.lastName,
    customerEmail: contact.email,
    customerPhone: contact.phone ? contact.phone.replace('+', '') : undefined,
    customerTitle: contact.businessTitle,
  });

  const getCustomerDetails = (customer: Customer) => ({
    ...getAccountValues(customer.account),
    ...getContactValues(customer.contact),
  });

  const getLifeCycleDetails = (lifeCycle: LifeCycle) => ({
    nextStep: lifeCycle.nextSteps,
    targetCloseDate: lifeCycle.targetCloseDate,
  });

  const getMarketingDetails = (marketing: Marketing) => ({
    isMarketingDevelopmentFunded: marketing.awsFundingUsed,
    campaignName: marketing.campaignName,
    marketingActivityChannel: marketing.channels,
    isOppFromMarketingActivity: marketing.source,
    marketingActivityUseCases: marketing.useCases,
  });

  const getProjectDetails = (project: Project) => ({
    additionalComments: project.additionalComments,
    apnPrograms: project.apnPrograms,
    competitiveTracking: project.competitorName,
    projectDescription: project.customerBusinessProblem,
    useCase: project.customerUseCase,
    deliveryModel: project.deliveryModels,
    expectedMonthlyRevenue: project.expectedMonthlyAwsRevenue.amount,
    otherCompetitorNames: project.otherCompetitorNames,
    salesActivities: project.salesActivities,
    projectTitle: project.title,
    otherSolutionDescription: project.otherSolutionDescription,
  });

  const getPartnerOpportunityTeamDetails = (
    team: PartnerOpportunityTeamMember[],
  ) => {
    const primaryContact = team[0];
    if (!primaryContact) {
      return {};
    }
    return {
      primaryContactEmail: primaryContact?.email,
      primaryContactFirstName: primaryContact?.firstName,
      primaryContactLastName: primaryContact?.lastName,
      primaryContactPhone: primaryContact?.phone
        ? primaryContact?.phone.replace('+', '')
        : null,
      primaryContactTitle: primaryContact.businessTitle,
    };
  };

  return {
    ...getCustomerDetails(response.customer),
    ...getProjectDetails(response.project),
    ...getLifeCycleDetails(response.lifeCycle),
    ...getMarketingDetails(response.marketing),
    ...getPartnerOpportunityTeamDetails(response.partnerOpportunityTeam),
    ...getAccessControls(response),
    partnerNeedType: getPartnerNeedType(response),
    opportunityType: response.opportunityType,
    primaryNeedsFromAws: response.primaryNeedsFromAws,
    awsProducts: response.awsProducts,
    solutions: response.solutions,
  };
};
