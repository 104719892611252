import React from 'react';
import { Sync } from 'mdi-material-ui';
import { Button } from 'vendor/material';
import { useAwsOpportunityActions } from '../../pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';

interface AwsCoSellSyncStatusProps {
  opportunityId: string;
}

export const AwsCoSellSyncStatus = (props: AwsCoSellSyncStatusProps) => {
  const { triggerSyncOpportunityById } = useAwsOpportunityActions();

  return (
    <p>
      Opportunity last synced with AWS Partner Central MM/DD/YYYY at HH:MM:SS.
      Updates every 1 hour, next sync in 0h 28m. <br />
      <Button
        onClick={() => triggerSyncOpportunityById(props.opportunityId)}
        size="small"
        startIcon={<Sync />}
      >
        Sync again
      </Button>
    </p>
  );
};
