import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  radioHelperText: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.NEUTRAL300,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
