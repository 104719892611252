import { Box, Typography } from 'vendor/material';
import { Formik, Form, FormikValues } from 'formik';
import { ObjectSchema } from 'yup';
import { useState, useEffect } from 'react';
import { Page } from 'components';
import { ConfirmModal, DELETE_DRAFT, TOASTS } from '../../components';
import { Alert, Provider, ProviderIcon } from '@tackle-io/platform-ui';
import useToast from 'hooks/useToast/useToast';
import { pendoIdGenerator } from 'utils/idGenerator';
import {
  cosellCancelModalElements,
  cosellDeleteModalElements,
} from 'utils/pendoEnums';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';
import { UnifiedOpportunityFormValues } from './UnifiedOpportunityFormValues';
import { UnifiedOpportunityFormButtonSection } from './UnifiedOpportunityFormButtonSection';
import { useCoSellHistory } from '../../hooks';
import { COSELL_DASHBOARD_PATH } from '../../utilities/constants';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { isEmpty } from 'lodash-es';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader';

export type ActiveOpportunityFormAction =
  | 'deleting'
  | 'submitting'
  | 'saving_draft';

interface UnifiedOpportunityFormProps {
  onSubmit: (values: UnifiedOpportunityFormValues) => any;
  onSaveAsDraft: (values: UnifiedOpportunityFormValues) => Promise<void>;
  validationSchema?: ObjectSchema<any>;
  initialValues: FormikValues;
  children: React.ReactNode;
  opportunityId?: string;
  opportunity?: AceOpportunityResponse;
  provider?: Provider;
  isAceV14?: boolean;
}

export const UnifiedOpportunityFormShell = ({
  children: formFields,
  onSubmit,
  onSaveAsDraft,
  validationSchema,
  initialValues,
  opportunityId,
  opportunity,
  provider,
}: UnifiedOpportunityFormProps) => {
  const classes = useOpportunityFormStyles();
  const history = useCoSellHistory();
  const { toaster } = useToast();
  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>(undefined);
  const [activeOpportunityAction, setActiveOpportunityAction] =
    useState<ActiveOpportunityFormAction>();
  // Scroll to top of page when errors are present
  const companyName = opportunity?.customer?.account?.companyName;
  useEffect(() => {
    if (errors) {
      const containerShell = document.querySelector('#root > div > main');
      if (containerShell && containerShell.scrollTop > 0) {
        containerShell.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }, [errors]);

  const redirectToOpportunityDetails = (opportunityId) => {
    history.push({
      pathname: `/co-sell/v3/opportunity/${provider.toLowerCase()}/${opportunityId}`,
    });
  };

  const redirectToCoSellDashboard = () => {
    history.push({
      pathname: COSELL_DASHBOARD_PATH,
      search: history.location.state?.coSellSearchParams,
    });
  };

  const handleError = (error) => {
    if (Array.isArray(error?.cause?.message?.detail)) {
      error?.cause?.message?.detail.map((err) => {
        return setErrors([`${error?.message}: ${err?.msg}`]);
      });
    } else {
      setErrors([`${error?.message}`]);
    }
  };

  const handleSubmitOpportunity = async (
    values: UnifiedOpportunityFormValues,
  ) => {
    setActiveOpportunityAction('submitting');
    try {
      const opportunity = await onSubmit(values);
      redirectToOpportunityDetails(opportunity?.id);
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  const handleSaveOpportunityAsDraft = async (
    values: UnifiedOpportunityFormValues,
  ) => {
    setActiveOpportunityAction('saving_draft');
    try {
      await onSaveAsDraft(values);
      redirectToCoSellDashboard();
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  const handleDeleteDraftOpportunity = async () => {
    setActiveOpportunityAction('deleting');
    try {
      // await onDeleteDraft();
      toaster(TOASTS[DELETE_DRAFT]);
      redirectToCoSellDashboard();
    } catch (error) {
      handleError(error);
    } finally {
      setActiveOpportunityAction(undefined);
    }
  };

  return (
    <Page>
      {opportunity?.identifier ? (
        <Box mt={2}>
          <AceOpportunityDetailsHeader
            title={opportunity?.customer?.account?.companyName}
            origin={opportunity?.origin}
            lifeCycle={opportunity?.lifeCycle}
          />
        </Box>
      ) : (
        <>
          <Box mt={4} display={'flex'}>
            <ProviderIcon fontSize={'large'} provider={provider} />
            <Typography className={classes.title}>
              {companyName ? companyName : 'Create a Co-Sell opportunity'}
            </Typography>
          </Box>
        </>
      )}
      {/* {opportunity?.submissionErrors && (
        <ErrorFeedbackAlert submissionErrors={opportunity.submissionErrors} />
      )} */}
      {errors?.map((error) => (
        <Box mb={2} key={error}>
          <Alert title={error} appearance="danger" />
        </Box>
      ))}
      {((opportunityId && !isEmpty(initialValues)) || !opportunityId) && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            // noop
          }}
        >
          <Form>
            {formFields}
            <UnifiedOpportunityFormButtonSection
              onSubmit={handleSubmitOpportunity}
              onSaveAsDraft={handleSaveOpportunityAsDraft}
              onCancel={() => setCancelConfirmModalOpen(true)}
              onDelete={() => setDeleteConfirmModalOpen(true)}
              opportunity={opportunity}
              activeOpportunityFormAction={activeOpportunityAction}
            />
          </Form>
        </Formik>
      )}
      <ConfirmModal
        open={deleteConfirmModalOpen}
        onClose={() => setDeleteConfirmModalOpen(false)}
        onSubmit={handleDeleteDraftOpportunity}
        title={'Are you sure you want to delete?'}
        body={'If you delete this draft, you will be unable to revisit it.'}
        pendoCancel={pendoIdGenerator(cosellDeleteModalElements.CANCEL)}
        pendoConfirm={pendoIdGenerator(cosellDeleteModalElements.CONFIRM)}
        cancelButtonLabel={'Cancel'}
        submitButtonLabel={'Delete draft'}
        submitButtonLoading={activeOpportunityAction === 'deleting'}
      />
      <ConfirmModal
        open={cancelConfirmModalOpen}
        onClose={() => setCancelConfirmModalOpen(false)}
        onSubmit={redirectToCoSellDashboard}
        title={'Are you sure you want to cancel?'}
        body={
          'If you leave this page without saving your changes, they will be lost.'
        }
        pendoCancel={pendoIdGenerator(cosellCancelModalElements.CANCEL)}
        pendoConfirm={pendoIdGenerator(cosellCancelModalElements.CONFIRM)}
        cancelButtonLabel={'Cancel'}
        submitButtonLabel={'Confirm'}
      />
    </Page>
  );
};
