import React from 'react';
import { Tag } from '../Tag';
import { TagColorEnum } from '../../types/enums';

export enum CoSellSyncStatus {
  DRAFT = 'Draft',
  SYNCED = 'Synced',
  SYNCING = 'Syncing',
  SYNC_ERROR = 'Sync Error',
  SALES_STAGE_OUT_OF_SYNC = 'Sales Stage out of sync',
}

const statusToColorMapping: { [key in CoSellSyncStatus]: TagColorEnum } = {
  [CoSellSyncStatus.DRAFT]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNCED]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNCING]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNC_ERROR]: TagColorEnum.RED,
  [CoSellSyncStatus.SALES_STAGE_OUT_OF_SYNC]: TagColorEnum.YELLOW,
};

interface CoSellSyncStatusTagProps {
  status: CoSellSyncStatus;
}

export const CoSellSyncStatusTag = (props: CoSellSyncStatusTagProps) => {
  return <Tag color={statusToColorMapping[props.status]}>{props.status}</Tag>;
};
