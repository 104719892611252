import { Grid } from 'vendor/material';
import { useFormikContext } from 'formik';
import {
  AiTextFormField,
  CurrencyFormField,
  DateFormField,
  MultiSelectFormField,
  RadioCheckboxGroupFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';

import { pendoIdGenerator } from 'utils/idGenerator';
import {
  cosellMarketingActivityFormSection,
  cosellOpportunityDetailsFormSection,
  cosellBuyerCompanyInformationFormSection,
  cosellAdditionalDetailsFormSection,
} from 'utils/pendoEnums';
import {
  AcePartnerNeedType,
  AceOpportunityTypeEnum,
  OpportunityIndustryEnum,
  CompetitiveTrackingEnum,
  FeatureFlagState,
} from 'packages/cosell/src/types/enums';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { CoSellFeatureFlags } from 'utils/features';
import { UnifiedOpportunityFormSection } from '../UnifiedOpportunityFormSection';
import { AssignmentIcon, PersonIcon } from 'packages/cosell/assets';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { OTHER } from '../FormFieldsConstants';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import {
  getAwsFundingUsedOptions,
  getNationalSecurityOptions,
  getOpportunitySourceOptions,
  getOpportunityTypeOptions,
  handleOpportunitySourceChange,
  handleOpportunityTypeChange,
  handlePartnerNeedTypeChange,
  isFieldEditable,
  UNITED_STATES_COUNTRY_CODE,
} from './helpers';
import { useEffect, useState } from 'react';
import { scrollToFirstError } from 'packages/cosell/src/utilities/scroll/scrollToFirstError';
import { isEmpty } from 'lodash';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';

const useCoSellFeatureFlags = () => {
  const { loading: ampliloading, flags: ampliflags } = useAmpliFeatureFlags();
  return {
    ampliloading,
    showAiGenerateButton:
      ampliflags[CoSellFeatureFlags.BETA_AI_COSELL_DESCRIPTION] ===
      FeatureFlagState.ON
        ? true
        : false,
  };
};

const AceOpportunityFormFields = () => {
  const { values, setFieldValue, errors, isValid, isValidating, submitCount } =
    useFormikContext<CreateAceOpportunityFormValues>();
  const { awsEnumListMap, solutionList, opportunity } = useOpportunity();
  const { ampliloading, showAiGenerateButton } = useCoSellFeatureFlags();
  const [hasScrolledToFirstError, setHasScrolledToFirstError] = useState(false);
  useEffect(() => {
    const shouldScrollToError =
      !isValid &&
      !isEmpty(errors) &&
      submitCount > 0 &&
      !hasScrolledToFirstError;
    if (!shouldScrollToError) {
      return;
    }

    const hasScrolled = scrollToFirstError();
    setHasScrolledToFirstError(hasScrolled);
  }, [errors, isValidating, isValid, submitCount, hasScrolledToFirstError]);

  useEffect(() => {
    setHasScrolledToFirstError(false);
  }, [submitCount]);

  useEffect(() => {
    const shouldScrollToError = !isValid && !isEmpty(errors) && submitCount > 0;
    if (!shouldScrollToError) {
      return;
    }

    scrollToFirstError();
  }, [errors, isValidating, isValid, submitCount]);

  const isOppFromMarketingActivity =
    values?.isOppFromMarketingActivity ===
    MarketingSourceEnum.MARKETING_ACTIVITY;

  return (
    <>
      <div>
        {/* Section 1: Customer Details */}
        <UnifiedOpportunityFormSection
          title="Customer details"
          titleIcon={<OfficeBuilding />}
        >
          <Grid item xs={12}>
            <TextFormField
              name="duns"
              label="Customer data universal number system (DUNS)"
              helperText="Customer DUNS is 9 digits. For example: 123456789"
              pendoId={pendoIdGenerator(
                cosellBuyerCompanyInformationFormSection.COSELL_BUYER_COMPANY_INFORMATION_DUNS_NUMBER,
              )}
              disabled={
                !isFieldEditable({
                  name: 'duns',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerCompanyName"
              label="Company name"
              disabled={
                !isFieldEditable({
                  name: 'customerCompanyName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="industry"
              label="Industry vertical"
              options={awsEnumListMap?.['customer.account.industry']}
              onChange={() => {
                setFieldValue('industryOther', null);
                setFieldValue('nationalSecurity', null);
              }}
              required
              disabled={
                !isFieldEditable({
                  name: 'industry',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.industry === OpportunityIndustryEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="industryOther"
                label="Industry other"
                disabled={
                  !isFieldEditable({
                    name: 'industryOther',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          {values.industry === OpportunityIndustryEnum.GOVERNMENT &&
          values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <RadioCheckboxGroupFormField
                id="nationalSecurity"
                label="Classified national security information"
                radioOptions={getNationalSecurityOptions(
                  awsEnumListMap?.['accessControls.nationalSecurity'],
                )}
                helperText="Specify whether this opportunity contains classified National Security information."
                required
                name={'nationalSecurity'}
                disabled={
                  !isFieldEditable({
                    name: 'nationalSecurity',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="customerWebsite"
              label="Customer website"
              disabled={
                !isFieldEditable({
                  name: 'customerWebsite',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="countryCode"
              data-testid="countrySingleSelectFormField"
              label="Country"
              onChange={() => setFieldValue('nationalSecurity', null)}
              options={awsEnumListMap?.['customer.account.address.countryCode']}
              disabled={
                !isFieldEditable({
                  name: 'countryCode',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="postalCode"
              label="Postal code"
              helperText={
                values.countryCode === UNITED_STATES_COUNTRY_CODE &&
                'United States postal code must be in 99999 OR 99999-9999 format.'
              }
              disabled={
                !isFieldEditable({
                  name: 'countryCode',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          {values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <SingleSelectFormField
                data-testid="stateTextFormField"
                fieldId="state"
                label="State/Province"
                options={
                  awsEnumListMap?.['customer.account.address.stateOrRegion']
                }
                disabled={
                  !isFieldEditable({
                    name: 'state',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
                required
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="city"
              label="City"
              disabled={
                !isFieldEditable({
                  name: 'city',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="address"
              label="Address"
              disabled={
                !isFieldEditable({
                  name: 'address',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 2: Project details */}
        <UnifiedOpportunityFormSection
          title="Project details"
          titleIcon={<AssignmentIcon />}
        >
          <Grid item xs={12}>
            {/* TODO: Update when component is merged */}
            <RadioCheckboxGroupFormField
              id="partnerNeedType"
              label="Partner primary need from AWS"
              radioOptions={[
                {
                  display: 'Co-sell with AWS',
                  value: AcePartnerNeedType.CO_SELL_WITH_AWS,
                  helperText:
                    'Share the opportunity with AWS to receive deal assistance and support.',
                },
                {
                  display: 'Do not need support from AWS sales rep',
                  value:
                    AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
                  helperText:
                    'Share the opportunity with AWS for visibility only, you will not receive deal assistance or support.',
                },
              ]}
              required
              name={'partnerNeedType'}
              onChange={(e) => handlePartnerNeedTypeChange(e, setFieldValue)}
              disabled={
                !isFieldEditable({
                  name: 'partnerNeedType',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.partnerNeedType !==
            AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP && (
            <Grid item xs={12}>
              <MultiSelectFormField
                fieldId={'primaryNeedsFromAws'}
                label={'Partner specific needs from AWS'}
                options={awsEnumListMap?.['primaryNeedsFromAws[]']}
                required={
                  values.partnerNeedType !==
                  AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
                }
                filterSelectedOptions={true}
                disabled={
                  !isFieldEditable({
                    name: 'primaryNeedsFromAws',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioCheckboxGroupFormField
              name="opportunityType"
              id="opportunityType"
              label="Opportunity type"
              radioOptions={getOpportunityTypeOptions(
                awsEnumListMap?.['opportunityType'],
              )}
              onChange={(e) => handleOpportunityTypeChange(e, setFieldValue)}
              required
              disabled={
                !isFieldEditable({
                  name: 'opportunityType',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.opportunityType === AceOpportunityTypeEnum.EXPANSION ||
          values.opportunityType === AceOpportunityTypeEnum.FLAT_RENEWAL ? (
            <Grid item xs={12}>
              <TextFormField
                label="Parent co-sell ID"
                name="parentOppId"
                pendoId={pendoIdGenerator(
                  cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_PARENT_COSELL_ID,
                )}
                disabled={
                  !isFieldEditable({
                    name: 'parentOppId',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="projectTitle"
              label="Partner project title"
              multiline={true}
              maxLength={255}
              rows={6}
              required
              disabled={
                !isFieldEditable({
                  name: 'projectTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId={'salesActivities'}
              label={'Sales activities'}
              options={awsEnumListMap?.['project.salesActivities[]']}
              disabled={
                !isFieldEditable({
                  name: 'salesActivities',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              // Sales activities is required when the partner needs support from AWS.
              required={
                values.partnerNeedType !==
                AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              helperText="Choose sales activities that have happened with the end-customer."
              filterSelectedOptions={true}
            />
          </Grid>
          <Grid item xs={12}>
            {!ampliloading && showAiGenerateButton ? (
              <AiTextFormField
                name="projectDescription"
                dependsOnFields={['customerWebsite']}
                label="Buyer business problem"
                multiline
                maxLength={599}
                minLength={20}
                placeholder="Add your own description, or select Generate with Tackle AI to get started"
                required
                disabled={
                  !isFieldEditable({
                    name: 'projectDescription',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            ) : (
              <TextFormField
                pendoId={
                  cosellAdditionalDetailsFormSection.COSELL_ADDITIONAL_DETAILS_BUYER_BUSINESS_PROBLEM
                }
                name="projectDescription"
                label="Buyer business problem"
                multiline
                rows={6}
                maxLength={32000}
                minLength={50}
                required
                disabled={
                  !isFieldEditable({
                    name: 'projectDescription',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="solutions"
              label="Solutions offered"
              onChange={() => setFieldValue('otherSolutionDescription', null)}
              options={solutionList}
              disabled={
                !isFieldEditable({
                  name: 'solutions',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
              filterSelectedOptions={true}
              dataId={pendoIdGenerator(
                cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_SOLUTIONS,
              )}
            />
          </Grid>
          {values.solutions?.includes(OTHER) ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherSolutionDescription"
                label="Other solution offered"
                disabled={
                  !isFieldEditable({
                    name: 'otherSolutionDescription',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId={'awsProducts'}
              label={'AWS products'}
              options={[]}
              disabled={
                !isFieldEditable({
                  name: 'awsProducts',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              // Sales activities is required when the partner needs support from AWS.
              required={
                false
                // values.partnerNeedType !==
                // AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              helperText="Choose one or more AWS products that will be utilized to solve the customer's business problem. Adding products enables AWS to connect you with the right support on this opportunity."
              filterSelectedOptions={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="nextStep"
              label="Next step"
              rows={6}
              multiline
              placeholder="Describe the next steps for this opportunity"
              maxLength={255}
              disabled={
                !isFieldEditable({
                  name: 'nextStep',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="useCase"
              filterSelectedOptions={true}
              label="Use case"
              options={awsEnumListMap?.['project.customerUseCase']}
              required
              disabled={
                !isFieldEditable({
                  name: 'useCase',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="deliveryModel"
              filterSelectedOptions={true}
              label="Delivery model"
              options={awsEnumListMap?.['project.deliveryModels[]']}
              required
              disabled={
                !isFieldEditable({
                  name: 'deliveryModel',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyFormField
              dataId={pendoIdGenerator(
                cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_ESTIMATED_AWS_MONTHLY_RECURRING_REVENUE,
              )}
              name="expectedMonthlyRevenue"
              label="Estimated AWS monthly recurring revenue"
              required
              disabled={
                !isFieldEditable({
                  name: 'expectedMonthlyRevenue',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DateFormField
              name="targetCloseDate"
              label="Target close date"
              required
              disabled={
                !isFieldEditable({
                  name: 'targetCloseDate',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="apnPrograms"
              label={'APN programs'}
              options={awsEnumListMap?.['project.apnPrograms[]']}
              filterSelectedOptions={true}
              required={false}
              disabled={
                !isFieldEditable({
                  name: 'apnPrograms',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 3: Marketing details */}
        <UnifiedOpportunityFormSection
          title="Marketing details"
          titleIcon={<AssignmentIcon />}
          tooltip="This helps AWS progress faster on the opportunity. If not filled, AWS will populate with opportunity submitter information."
        >
          <Grid item xs={12}>
            <RadioCheckboxGroupFormField
              label={'Opportunity source'}
              id={pendoIdGenerator(
                cosellMarketingActivityFormSection.IS_OPP_FROM_MARKETING_ACTIVITY,
              )}
              name="isOppFromMarketingActivity"
              required
              radioOptions={getOpportunitySourceOptions(
                awsEnumListMap?.['marketing.source'],
              )}
              onChange={(e) => handleOpportunitySourceChange(e, setFieldValue)}
              disabled={
                !isFieldEditable({
                  name: 'isOppFromMarketingActivity',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {isOppFromMarketingActivity && (
            <>
              <Grid item xs={12}>
                <TextFormField
                  pendoId={pendoIdGenerator(
                    cosellMarketingActivityFormSection.AWS_MARKETING_SALESFORCE_CAMPAIGN_NAME,
                  )}
                  name="campaignName"
                  label="Marketing campaign"
                  disabled={
                    !isFieldEditable({
                      name: 'campaignName',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  fieldId={'marketingActivityUseCases'}
                  label={'Marketing activity use case'}
                  options={awsEnumListMap?.['marketing.useCases[]']}
                  disabled={
                    !isFieldEditable({
                      name: 'marketingActivityUseCases',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  fieldId={'marketingActivityChannel'}
                  label={'Marketing activity channel'}
                  options={awsEnumListMap?.['marketing.channels[]']}
                  disabled={
                    !isFieldEditable({
                      name: 'marketingActivityChannel',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioCheckboxGroupFormField
                  id="isMarketingDevelopmentFunded"
                  label="Marketing development funds"
                  radioOptions={getAwsFundingUsedOptions(
                    awsEnumListMap?.['marketing.awsFundingUsed'],
                  )}
                  required
                  name={'isMarketingDevelopmentFunded'}
                  disabled={
                    !isFieldEditable({
                      name: 'isMarketingDevelopmentFunded',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                />
              </Grid>
            </>
          )}
        </UnifiedOpportunityFormSection>

        {/* Section 4: Additional Details */}
        <UnifiedOpportunityFormSection
          title="Additional details"
          titleIcon={<AssignmentIcon />}
        >
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={
              // }
              fieldId="competitiveTracking"
              data-testid="competitiveTracking"
              label="Competitive tracking"
              onChange={() => setFieldValue('otherCompetitorNames', null)}
              options={awsEnumListMap?.['project.competitorName']}
              disabled={
                !isFieldEditable({
                  name: 'competitiveTracking',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.competitiveTracking === CompetitiveTrackingEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherCompetitorNames"
                label="Other competitors"
                disabled={
                  !isFieldEditable({
                    name: 'otherCompetitorNames',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="awsAccountId"
              label="AWS account ID"
              helperText="12 digit number. For example: 123456789012"
              disabled={
                !isFieldEditable({
                  name: 'awsAccountId',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="additionalComments"
              label="Additional comments"
              multiline
              rows={6}
              helperText="Enter project description, opportuntinty details, customer pain points, customer needs, etc."
              maxLength={255}
              disabled={
                !isFieldEditable({
                  name: 'additionalComments',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>
        {/* Section 5: Customer contact */}
        <UnifiedOpportunityFormSection
          title="Customer contact"
          titleIcon={<PersonIcon />}
        >
          <Grid item xs={12}>
            <TextFormField
              name="customerFirstName"
              label="Customer first name"
              disabled={
                !isFieldEditable({
                  name: 'customerFirstName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerLastName"
              label="Customer last name"
              disabled={
                !isFieldEditable({
                  name: 'customerLastName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerTitle"
              label="Customer title"
              disabled={
                !isFieldEditable({
                  name: 'customerTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerEmail"
              label="Customer email"
              disabled={
                !isFieldEditable({
                  name: 'customerEmail',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerPhone"
              label="Customer phone"
              disabled={
                !isFieldEditable({
                  name: 'customerPhone',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 6: Partner contact */}
        <UnifiedOpportunityFormSection
          title="Partner contact"
          titleIcon={<PersonIcon />}
          tooltip="AWS sales team may reach out to this contact in regard to the opportunity."
        >
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactFirstName"
              label="Primary contact first name"
              disabled={
                !isFieldEditable({
                  name: 'primaryContactFirstName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactLastName"
              label="Primary contact last name"
              disabled={
                !isFieldEditable({
                  name: 'primaryContactLastName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactTitle"
              label="Primary contact title"
              disabled={
                !isFieldEditable({
                  name: 'primaryContactTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactEmail"
              label="Primary contact email"
              disabled={
                !isFieldEditable({
                  name: 'primaryContactEmail',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactPhone"
              label="Primary contact phone"
              disabled={
                !isFieldEditable({
                  name: 'primaryContactPhone',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>
      </div>
    </>
  );
};

export default AceOpportunityFormFields;
