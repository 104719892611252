import React from 'react';
import { useSalesforceCoSellWidgetHeaderStyles } from './SalesforceCoSellWidgetHeader.styles';
import TackleLogo from 'images/tackle-logo';
import { Button } from 'vendor/material';
import { OpenInNew } from 'mdi-material-ui';

export const SalesforceCoSellWidgetHeader = () => {
  const classes = useSalesforceCoSellWidgetHeaderStyles();

  return (
    <div className={classes.container}>
      <TackleLogo theme={'light'} width="104px" />

      <div className={classes.openInTackleIconContainer}>
        <Button
          href="https://downstream.tackle.io/co-sell"
          target="_blank"
          size="small"
          endIcon={<OpenInNew />}
        >
          Open in Tackle
        </Button>
      </div>
    </div>
  );
};
