import React, { useEffect, useState } from 'react';
import coSellClient from '../../../api/coSellClient';
import { useOpportunity } from '../UnifiedOpportunityForm/providers/OpportunityProvider';
import AceOpportunityDetails from './AceOpportunityDetails/AceOpportunityDetails';

interface UnifiedOpportunityDetailsProps {
  opportunityId: string;
}

const UnifiedOpportunityDetails: React.FC<UnifiedOpportunityDetailsProps> = ({
  opportunityId,
}) => {
  const { token } = useOpportunity();
  const [opportunity, setOpportunity] = useState(null);

  useEffect(() => {
    const fetchOpportunity = async () => {
      if (!token) {
        console.error('Token is not available');
        return;
      }

      const authorizedCoSellClient = coSellClient(token);

      if (!authorizedCoSellClient) {
        console.error('Failed to initialize coSellClient');
        return;
      }

      try {
        const response = await authorizedCoSellClient.getOpportunityByIdV3(
          opportunityId,
        );
        setOpportunity(response);
      } catch (error) {
        console.error('Failed to fetch opportunity', error);
      }
    };

    if (!opportunity) {
      fetchOpportunity();
    }
  }, [token, opportunityId, opportunity]);

  if (!opportunity) {
    return <div>No co-sell opportunity found</div>; //TODO: add no opportunity found component
  }

  return <AceOpportunityDetails opportunity={opportunity} />;
};

export default UnifiedOpportunityDetails;
