import React from 'react';
import { Box } from 'vendor/material';
import { Page } from 'components';
import AceOpportunityDetailsHeader from './detailSections/AceOpportunityDetailsHeader';
import NextStepsCard from './detailSections/NextStepsCard';
import AceCustomerDetails from './detailSections/AceCustomerDetails';
import AceProjectDetails from './detailSections/AceProjectDetails';
import AceMarketingDetails from './detailSections/AceMarketingDetails';
import AceAdditionalDetails from './detailSections/AceAdditionalDetails';
import AceContactDetails from './detailSections/AceContactDetails';
import AcePartnerDetails from './detailSections/AcePartnerDetails';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

interface AceSingleOpportunityProps {
  opportunity: AceOpportunityResponse;
}

const AceOpportunityDetails: React.FC<AceSingleOpportunityProps> = ({
  opportunity,
}) => {
  const additionalDetails = {
    awsAccountId: opportunity?.customer?.account.awsAccountId,
    additionalComments: opportunity?.project?.additionalComments,
    partnerOpportunityIdentifier: opportunity?.partnerOpportunityIdentifier,
    competitiveTracking: opportunity?.project?.competitorName,
  };

  return (
    <Page>
      <Box mt={2}>
        <AceOpportunityDetailsHeader
          title={opportunity?.project?.title}
          origin={opportunity?.origin}
          lifeCycle={opportunity?.lifeCycle}
        />
        <Box mt={4}>
          <NextStepsCard
            nextSteps={opportunity?.lifeCycle?.nextSteps}
            nextStepsHistory={opportunity?.lifeCycle?.nextStepsHistory}
          />
        </Box>
        <Box mt={4}>
          <AceCustomerDetails account={opportunity?.customer?.account} />
        </Box>
        <Box mt={4}>
          <AceProjectDetails
            project={opportunity?.project}
            targetCloseDate={opportunity?.lifeCycle?.targetCloseDate}
            awsProducts={opportunity?.awsProducts}
            solutions={opportunity?.solutions}
          />
        </Box>
        <Box mt={4}>
          <AceMarketingDetails marketing={opportunity?.marketing} />
        </Box>

        <Box mt={4}>
          <AceAdditionalDetails additionalDetails={additionalDetails} />
        </Box>

        <Box mt={4}>
          <AceContactDetails contact={opportunity?.customer?.contact} />
        </Box>

        {opportunity?.partnerOpportunityTeam?.length > 0 &&
          opportunity?.partnerOpportunityTeam.map((teamMember, i) => (
            <Box mt={4} key={i}>
              <AcePartnerDetails partnerContact={teamMember} />
            </Box>
          ))}
      </Box>
    </Page>
  );
};

export default AceOpportunityDetails;
