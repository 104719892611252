import { createContext, useContext, useEffect, useState } from 'react';
import {
  getTackleSessionFromOtk,
  IOneTimeKeyExchangeResponse,
} from '../lib/salesforceCanvasSession';

const ONE_TIME_KEY_PARAM = 'otk';

interface CanvasSessionContextProps {
  isLoadingCanvasSession: boolean | null;
  canvasSession: IOneTimeKeyExchangeResponse | null;
}

export const CanvasSessionContext = createContext<CanvasSessionContextProps>({
  isLoadingCanvasSession: null,
  canvasSession: null,
});

export interface CanvasSessionProviderProps {
  children?: React.ReactElement;
}

export const CanvasSessionProvider = ({
  children,
}: CanvasSessionProviderProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const otk = urlParams.get(ONE_TIME_KEY_PARAM);

  const [canvasSession, setCanvasSession] =
    useState<IOneTimeKeyExchangeResponse>();
  const [isLoadingCanvasSession, setisLoadingCanvasSession] = useState(true);

  useEffect(() => {
    async function callGetcanvasSessionFromOtk() {
      const canvasSession = await getTackleSessionFromOtk(otk);
      setCanvasSession(canvasSession);
      setisLoadingCanvasSession(false);
    }

    if (!canvasSession && otk) {
      callGetcanvasSessionFromOtk();
    }
  }, [canvasSession, otk]);

  return (
    <CanvasSessionContext.Provider
      value={{
        canvasSession,
        isLoadingCanvasSession,
      }}
    >
      {children}
    </CanvasSessionContext.Provider>
  );
};

export function useCanvasSession() {
  const context = useContext(CanvasSessionContext);
  if (context === undefined) {
    throw new Error(
      'useCanvasSession must be used within a CanvasCoSellDataProvider',
    );
  }
  return context;
}
