import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetHeaderStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.TEAL900,
    padding: '28px 20px 32px 20px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  openInTackleIconContainer: {
    color: '#fff',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'end',
    '& > a': {
      color: '#ffffff !important',
    },
  },
  openInTackleIcon: {
    marginLeft: '6px',
  },
}));
